<template>
  <b-container class="quizes-list">
    <b-row>
      <b-col>
        <h1>
          {{ $t("Quizes") }}
          <b-link :to="{ name: 'Options' }">
            <b-icon-arrow-return-left />
          </b-link>
        </h1>
        <b-breadcrumb :items="br_items" />
      </b-col>
    </b-row>
    <b-row>
      <b-form inline class="w-100">
        <label class="lab"> {{ $t("Date") }} </label>
        <date-picker v-model="date_filter" :request="req" />
        <label class="lab"> {{ $t("Departament") }} </label>
        <b-select
          :options="depratament_list"
          v-model="departament"
          value-field="pk"
          text-field="_name"
        />
        <label class="lab"> {{ $t("Category") }} </label>
        <b-select
          :options="category_list"
          v-model="category"
          value-field="pk"
          text-field="_name"
        />
        <label class="lab">{{ $t("Finished") }}</label>
        <b-checkbox v-model="started"></b-checkbox>
      </b-form>
    </b-row>
    <br />
    <b-overlay :show="loading">
      <b-row v-if="resp.length">
        <b-table :items="quizes" :fields="fields">
          <template v-slot:cell(actions)="item">
            <b-button-group>
              <b-button
                variant="outline-secondary"
                @click="show(item.item)"
                size="lg"
              >
                <b-icon-eye />
              </b-button>
              <b-button
                v-if="!item.item.deleted"
                @click="mark_as_deleted(item.item)"
                size="lg"
                variant="outline-danger"
                v-b-tooltip="'mark as deleted'"
              >
                <b-icon-trash />
              </b-button>
            </b-button-group>
          </template>
        </b-table>
        <b-pagination :per-page="perPage" v-model="page" :total-rows="total" />
      </b-row>
      <h2 v-else>{{ $t("Nothing found") }}</h2>
    </b-overlay>
    <b-modal
      hide-footer
      @close="close"
      @hide="close"
      :title="`Quiz: ${as_name(current_quiz.category)}`"
      v-model="show_modal"
      size="xl"
    >
      <b-table-simple>
        <b-tr>
          <b-th>UUID</b-th>
          <b-td>{{ current_quiz.uuid }}</b-td>
        </b-tr>
        <b-tr>
          <b-th>Date</b-th>
          <b-td>{{ current_quiz.date | date_format }}</b-td>
        </b-tr>
        <b-tr>
          <b-th>Departament</b-th>
          <b-td>{{ as_name(current_quiz.departament, "departament") }}</b-td>
        </b-tr>
        <b-tr v-if="current_quiz.finished">
          <b-th>Finished</b-th>
          <b-td>{{ current_quiz.finished | date_format }}</b-td>
        </b-tr>
        <b-tr>
          <b-th>Score</b-th>
          <b-td> {{ current_quiz.score }}</b-td>
        </b-tr>
        <b-tr>
          <b-th>Total score</b-th>
          <b-td> {{ current_quiz.p_score | p_score }}</b-td>
        </b-tr>
        <b-tr v-for="i in current_quiz.info" :key="i">
          <b-td colspan="2"> {{ as_name(i, "additional_info") }} </b-td>
        </b-tr>
      </b-table-simple>
      <b-table-simple responsive>
        <b-tr v-for="a in current_answers" :key="a.quiestion__uuid">
          <b-th
            v-b-tooltip="
              translate_option(as_name(a.question__uuid, 'question', 'uuid'))
            "
          >
            {{
              translate_option(
                as_name(a.question__uuid, "question", "uuid")
              ).slice(0, 40)
            }}
            ...
          </b-th>
          <b-td>{{ a.value | value }}</b-td>
          <b-td>{{ a.action }}</b-td>
          <b-td class="nowrap">{{ a.deadline | date_format }} </b-td>
          <b-td>{{ as_name(a.owner, "additional_info") }}</b-td>
        </b-tr>
      </b-table-simple>
    </b-modal>
  </b-container>
</template>

<script>
import DatePicker from "../components/DateTimePicker";
import { date_format, dict_to_search, capitalyze, getName } from "../shared";
import { pickBy, merge, orderBy } from "lodash-es";

export default {
  name: "Quizes",
  components: { DatePicker },
  filters: {
    date_format: (v) => (v ? date_format(new Date(v)) : ""),
    capitalyze,
    value: (v) => (v === null ? "-" : v ? "1" : "0"),
    p_score: (v) => `${(parseFloat(v) * 100).toFixed(0)} %`,
  },
  mounted() {
    this.load(true);
    this.$watch(
      () =>
        JSON.stringify({
          ...this.date_filter,
          c: this.category,
          d: this.departament,
          p: this.page,
          s: this.started,
        }),
      () => {
        this.load();
      }
    );
    this.$watch(
      () => this.total,
      () => (this.page = 1)
    );
  },
  data() {
    return {
      loading: false,
      date_filter: { start: new Date(), period: "week", end: null },
      category: null,
      departament: null,
      page: 1,
      perPage: 10,
      current_quiz: {},
      total: 0,
      resp: [],
      show_modal: false,
      started: true,
    };
  },
  computed: {
    quizes() {
      let ret = this.resp;
      return ret;
    },
    br_items() {
      return [
        { text: this.$t("Home"), to: { name: "Home" } },
        { text: this.$t("Settings"), to: { name: "Options" } },
        { text: this.$t("Quizes"), active: true },
      ];
    },
    current_answers() {
      return orderBy(this.current_quiz.answers || [], ["order"]);
    },
    req() {
      let ret = {};
      if (this.category) {
        ret["category"] = this.category;
      }
      if (this.departament) {
        ret["departament"] = this.departament;
      }
      return ret;
    },
    fields() {
      const cat = this.category_list.reduce(
        (r, c) => merge(r, { [c.pk]: c.name }),
        {}
      );
      // const info = this.$store.getters.additional_info.reduce(
      //   (r, i) => merge(r, {
      //     [i.pk]: i.name
      //   }), {});

      const persons = this.$store.getters.person.reduce(
        (r, i) =>
          merge(r, {
            [i.pk]: i.name,
          }),
        {}
      );

      const operators = this.$store.getters.operator.reduce(
        (r, i) =>
          merge(r, {
            [i.pk]: i.name,
          }),
        {}
      );

      const lines = this.$store.getters.line.reduce(
        (r, i) =>
          merge(r, {
            [i.pk]: i.name,
          }),
        {}
      );

      const cells = this.$store.getters.cell.reduce(
        (r, i) =>
          merge(r, {
            [i.pk]: i.name,
          }),
        {}
      );

      const departaments = this.$store.getters.departament.reduce(
        (r, d) => merge(r, { [d.pk]: d.name }),
        {}
      );
      return [
        {
          key: "category",
          label: this.$t("Category"),
          formatter: (v) => cat[v],
        },
        {
          key: "departament",
          label: this.$t("Departament"),
          formatter: (v) => departaments[v],
        },

        {
          key: "person",
          label: this.$t("Person"),
          formatter: (v, k, i) =>
            i.info.map((p) => persons[p] || null).find((p) => p),
        },
        {
          key: "operator",
          label: this.$t("Operator"),
          formatter: (v, k, i) =>
            i.info.map((p) => operators[p] || null).find((p) => p),
        },
        {
          key: "line",
          label: this.$t("Line"),
          formatter: (v, k, i) =>
            i.info.map((p) => lines[p] || null).find((p) => p),
        },
        {
          key: "cell",
          label: this.$t("Cell"),
          formatter: (v, k, i) =>
            i.info.map((p) => cells[p] || null).find((p) => p),
        },
        // {
        //   key: 'info',
        //   label: this.$t('Line, Cell, Persons'),
        //   formatter: v => v.map(i => info[i] || null).filter(i => i).join(', ')
        // },
        {
          key: "date",
          label: this.$t("Date"),
          sortable: true,
          formatter: (v) => date_format(new Date(v)),
        },
        {
          key: "finished",
          label: this.$t("Finished"),
          sortable: true,
          formatter: (v) => (v ? date_format(new Date(v)) : ""),
        },
        { key: "score", label: this.$t("Score"), sortable: true },
        {
          key: "p_score",
          label: this.$t("Total score"),
          formatter: (k) => `${(parseFloat(k) * 100).toFixed(0)} %`,
        },
        { key: "actions", label: this.$t("Actions"), tdClass: "actions_cell" },
      ];
    },
    category_list() {
      return [
        { pk: null, _name: "-----" },
        ...this.$store.getters.category.filter((c) => !c.mark_as_deleted),
      ];
    },
    depratament_list() {
      return [
        { pk: null, _name: "-----" },
        ...this.$store.getters.departament.filter((c) => !c.deleted),
      ];
    },
    to_search() {
      let ret = pickBy(this.date_filter, (v) => v !== null);
      if (this.category) {
        ret["category"] = this.category;
      }
      if (this.departament) {
        ret["departament"] = this.departament;
      }
      ret["from"] = (this.page - 1) * this.perPage;
      ret["finished"] = this.started;
      return dict_to_search(ret);
    },
  },
  methods: {
    translate_option(option) {
      return option[this.$i18n.locale] || option["en"] || "";
    },
    as_name(id, type = "category", key = "pk") {
      const obj = this.$store.getters[type].find((o) => o[key] == id);
      return getName(obj);
    },
    show(item) {
      this.current_quiz = item;
      this.show_modal = true;
    },
    close() {
      this.current_quiz = {};
      this.show_modal = false;
    },
    mark_as_deleted(item) {
      if (confirm("Are your really want delete this quiz?")) {
        this.$store
          .dispatch("put", {
            url: `api/v2/quiz/${item.uuid}/`,
            data: {
              deleted: new Date(),
            },
          })
          .then(this.load, this.load);
      }
    },
    load(from_mounted = false) {
      this.loading = true;
      let promises = [
        this.$store
          .dispatch("get", {
            url: `api/reports/qq/?${this.to_search}`,
          })
          .then((r) => {
            this.resp = r.items;
            this.total = r.total;
          }),
      ];
      if (from_mounted) {
        promises.push(
          this.$store.dispatch("load_data", [
            "category",
            "additional_info",
            "question",
            "departament",
          ])
        );
      }
      Promise.all(promises).then(
        () => (this.loading = false),
        () => (this.loading = false)
      );
    },
  },
};
</script>

<style lang="scss">
.quizes-list {
  .lab {
    margin-left: 1em;
    margin-right: 1em;
  }
}
</style>
